<template>
    <div class="footer">
      <p>COPYRIGHT &copy; 華匯國際 GENTING GROUP RESERVED</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
  };
  </script>
  
  <style scoped>
  /* Copy footer CSS here */
  .footer{
    overflow: hidden;
    background: #000;
    display: inline-block;
    left: 15%;
    position: relative;
    p{
      color: #969696;
    font-size: 0.25rem;
    line-height: 1.05rem;
    text-align: center;
    }
  }
  </style>