<template>
  <div class="main">
    <div class="photo"><img src="../assets/bg3.png" alt="" /></div>
    <ul class="navi clearfix">
      <li class="li01">
        <a href="index.html"><span class="img"><img src="../assets/img01.png" alt="" /></span><span
            class="txt"><span>进入官网</span></span></a>
        <div class="jsBox">
          <img class="arrow" src="../assets/arrow.png" alt="" />
          <ul class="ulList">
            <li>
              <span class="ms" id="lineMs0">检测中</span><img src="../assets/icon.png" alt="" /><span
                class="url">www.hh40090051.com</span><a target="_blank" class="thcom" href="https://yunding.master9.net/m">立即进入</a>
            </li>
            <li>
              <span class="ms" id="lineMs1">检测中</span><img src="../assets/icon.png" alt="" /><span
                class="url">www.hh40090052.com</span><a target="_blank" class="thcom" href="https://yunding.master9.net/m">立即进入</a>
            </li>
            <li>
              <span class="ms" id="lineMs2">检测中</span><img src="../assets/icon.png" alt="" /><span
                class="url">www.hh40090071.com</span><a target="_blank" class="thcom" href="https://yunding.master9.net/m">立即进入</a>
            </li>
            <li>
              <span class="ms" id="lineMs3">检测中</span><img src="../assets/icon.png" alt="" /><span
                class="url">www.hh40090061.com</span><a target="_blank" class="thcom" href="https://yunding.master9.net/m">立即进入</a>
            </li>
            <li>
              <span class="ms" id="lineMs4">检测中</span><img src="../assets/icon.png" alt="" /><span
                class="url">www.hh40090064.com</span><a target="_blank" class="thcom" href="https://yunding.master9.net/m">立即进入</a>
            </li>
            <li>
              <span class="ms" id="lineMs5">检测中</span><img src="../assets/icon.png" alt="" /><span
                class="url">www.hh40090063.com</span><a target="_blank" class="thcom" href="https://yunding.master9.net/m">立即进入</a>
            </li>
          </ul>
        </div>
      </li>
      <!-- <li class="li02">
        <a href="https://yunding.master9.net/m#/theme1_active"><span class="img"><img src="../assets/img02.png" alt="" /></span><span
            class="txt"><span>优惠大厅</span></span></a>
      </li> -->
      <li class="li03">
        <a href="https://yunding.master9.net/m#/theme1_active" target="_blank"><span class="img"><img src="../assets/img02.png"
              alt="" /></span><span class="txt"><span>优惠大厅</span></span></a>
      </li>
      <li class="li03">
        <a href="https://xiazai.yd9966.com/" target="_blank"><span class="img"><img src="../assets/img03.png"
              alt="" /></span><span class="txt"><span>APP下载</span></span></a>
      </li>
      <li class="li05">
        <a href="https://yd9966.com/chatlink.html?agentid=69c2f09726e4d6eeac36194f53aee326" target="_blank"><span class="img"><img src="../assets/img05.png"
              alt="" /></span><span class="txt"><span>在线客服</span></span></a>
      </li>
    </ul>

  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'MainContent',
  data() {
    return {
      urlGroup: [
        'www.hh40090051.com',
        'www.hh40090052.com',
        'www.hh40090071.com',
        'www.hh40090061.com',
        'www.hh40090064.com',
        'www.hh40090063.com'
      ],
      protGroup: ['80', '80', '80', '80', '80', '80', '80', '80', '80', '80']
    };
  },
  mounted() {
    $(".li01 > a").click(function () {
      $(this).parent().toggleClass("on").siblings("li").removeClass("on");
      return false;
    });
    $(".li02 > a").click(function () {
      $(this).parent().toggleClass("on").siblings("li").removeClass("on");
      return false;
    });
    $(".li04").click(function () {
      $(".saBox").toggle();
    });
    // $(".thcom").click(function () {
    //   const urlGroup = [
    //     'hh4009.com',
    //     'hh4009.com',
    //     'hh4009.com',
    //     'hh4009.com',
    //     'hh4009.com',
    //     'hh4009.com'
    //   ];
    //   let url = urlGroup[Math.floor(Math.random() * urlGroup.length)];
    //   let port = this.protGroup[Math.floor(Math.random() * this.protGroup.length)];
    //   $(this).attr('href', `https://www${new Date().getDate() + 3}${new Date().getMonth() + 1}${new Date().getFullYear()}${new Date().getHours()}${new Date().getDate()}${new Date().getDate() + 3}.${url}:${port}`);
    // });
  },
  method() {

  }
};
</script>

<style scoped>
/* Copy main content CSS here */
.main {
  margin: 0;
  padding: 0;
  z-index: -1;

  .photo {
    img {
      vertical-align: top;
      width: 100%;
    }
  }
}

.navi {
  margin: 0.2rem;
  position: relative;
  border: 1px solid #232323;
  padding-inline-start: 0 !important;

  .li01 .jsBox {
    display: none;
  }

  .li01.on .jsBox {
    display: block;
  }
  .li01.on > a {
  color: #000;
  background: url(../assets/yellow.jpg) repeat-x left top;
  background-size: auto 100%;
}



  .jsBox {
    width: 100%;
    position: absolute;
    left: 0;
    top: -0.22rem;
    display: none;
    box-sizing: border-box;
    border: 1px solid #d0ba53;
    background: rgba(0, 0, 0, 0.8);
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);

    .arrow {
      margin-left: -0.12rem;
      width: 0.22rem;
      position: absolute;
      left: 25%;
      bottom: -0.12rem;
    }

    .ulList {
      padding: 0.41rem 0.4rem 0.11rem;


      li {
        margin-bottom: 0.28rem;
        overflow: hidden;
        zoom: 1;

        .ms {
          display: block;
          float: left;
          color: #313131;
          font-size: 0.3rem;
          line-height: 0.55rem;
          text-align: center;
          border: 1px solid #c8c8c8;
          background: #f0f0f0;
          box-sizing: border-box;
          width: 1.1rem;
        }

        img {
          margin: 0.18rem 0.28rem 0 0.36rem;
          width: 0.45rem;
          display: block;
          float: left;

          vertical-align: top;
        }

        .url {
          display: block;
          float: left;
          color: #313131;
          font-size: 0.3rem;
          line-height: 0.55rem;
          text-align: center;
          border: 1px solid #c8c8c8;
          background: #f0f0f0;
          box-sizing: border-box;
          width: 4.14rem;
        }

        a {
          width: 2.15rem;
          display: block;
          float: right;
          color: #000;
          font-size: 0.3rem;
          font-weight: bold;
          line-height: 0.55rem;
          text-align: center;
          border: 1px solid #d0ba53;
          background: #d0ba53;
          box-sizing: border-box;
        }
      }


    }
  }

  .li01 {
    width: 50%;
    float: left;
  }

  .li02 {
    position: relative;
    width: 50%;
    float: left;
  }

  .li03 {
    width: 50%;
    float: left;
  }

  .li04 {
    width: 50%;
    float: left;
  }

  .li05 {
    width: 50%;
    float: left;
  }


  >li>a {
    display: flex;
    flex-direction: column;
    padding: 0.76rem 0 0;
    height: 2.61rem;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    border-right: 1px solid #232323;
    background: #101010;
    position: relative;
    border-bottom: 1px solid #232323;
    img{
      width: 48px;
      height: 48px;
    }
  }

}
</style>